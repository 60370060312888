import { createSlice } from '@reduxjs/toolkit'
import {
  getPatients,
  deletePatient,
  editPatient,
  getPatient,
  getAssignees,
  getAssignedPatients
} from './patient.action'

const initialState = {
  isPatientsLoading: false,
  isPatientsLoaded: false,
  isPatientsLoadingFailed: false,
  createPatientError: null,
  patientsCurrentPage: 0,
  patientsTotalPages: 0,
  patients: [],

  /****************************/
  isPatientLoading: false,
  isPatientLoaded: false,
  isPatientLoadingFailed: false,
  patient: {},

  /****************************/
  isAssigneesLoading: false,
  isAssigneesLoaded: false,
  isAssigneesLoadingFailed: false,
  assignees: [],

  /****************************/
  isAssignedPatientsLoading: false,
  isAssignedPatientsLoaded: false,
  isAssignedPatientsLoadingFailed: false,
  createAssignedPatientError: null,
  assignedPatientsCurrentPage: 0,
  assignedPatientsTotalPages: 0,
  assignedPatients: []
}

const patients = createSlice({
  name: 'patients',
  initialState,

  extraReducers: builder => {
    builder
      // Handle getPatients action
      .addCase(getPatients.pending, state => {
        state.isPatientsLoading = true
        state.isPatientsLoaded = false
        state.isPatientsLoadingFailed = false
      })
      .addCase(getPatients.fulfilled, (state, action) => {
        state.isPatientsLoading = false
        state.isPatientsLoaded = true
        state.patients = action.payload.documents
        state.patientsCurrentPage = action.payload.currentPage
        state.patientsTotalPages = action.payload.totalPages
      })
      .addCase(getPatients.rejected, state => {
        state.isPatientsLoading = false
        state.isPatientsLoadingFailed = true
      })

      //delete

      .addCase(deletePatient.pending, state => {
        state.isDeletingPatient = true
        state.deletePatientError = null
      })
      .addCase(deletePatient.fulfilled, (state, action) => {
        state.isDeletingPatient = false
        state.patients = state.patients.filter(patient => patient.id !== action.payload.id)
      })
      .addCase(deletePatient.rejected, (state, action) => {
        state.isDeletingPatient = false
        state.deletePatientError = action.error.message
      }) //edit
      .addCase(editPatient.fulfilled, (state, action) => {
        state.isEditingPatient = false
        state.isPatientEdited = true
        state.patients = state.patients.map(patient => (patient._id === action.payload._id ? action.payload : patient))
      })

      /*******************************************************/
      .addCase(getPatient.pending, state => {
        state.isPatientLoading = true
        state.isPatientLoaded = false
        state.isAssigneesLoadingFailed = false
      })
      .addCase(getPatient.fulfilled, (state, action) => {
        state.isPatientLoading = false
        state.isPatientLoaded = true
        state.patient = action.payload
      })
      .addCase(getPatient.rejected, state => {
        state.isPatientLoading = false
        state.isPatientLoadingFailed = true
      })

      /*******************************************************/
      .addCase(getAssignees.pending, state => {
        state.isAssigneesLoading = true
        state.isAssigneesLoaded = false
        state.isAssigneesLoadingFailed = false
      })
      .addCase(getAssignees.fulfilled, (state, action) => {
        state.isAssigneesLoading = false
        state.isAssigneesLoaded = true
        state.assignees = action.payload
      })
      .addCase(getAssignees.rejected, state => {
        state.isAssigneesLoading = false
        state.isAssigneesLoadingFailed = true
      })

      /*******************************************************/
      .addCase(getAssignedPatients.pending, state => {
        state.isAssignedPatientsLoading = true
        state.isAssignedPatientsLoaded = false
        state.isAssignedPatientsLoadingFailed = false
      })
      .addCase(getAssignedPatients.fulfilled, (state, action) => {
        state.isAssignedPatientsLoading = false
        state.isAssignedPatientsLoaded = true
        state.assignedPatients = action.payload
      })
      .addCase(getAssignedPatients.rejected, state => {
        state.isAssignedPatientsLoading = false
        state.isAssignedPatientsLoadingFailed = true
      })
  }
})

export default patients.reducer
