import { useState } from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { DataGrid } from '@mui/x-data-grid'
import CustomAvatar from 'src/@core/components/mui/avatar'
import { getInitials } from 'src/@core/utils/get-initials'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import { assignPatient, deletePatient, editPatient } from 'src/redux/patient/patient.action'
import { Icon } from '@iconify/react'
import { AssignPatientModal } from './assignPatientModal'

const LinkStyled = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1rem',
  cursor: 'pointer',
  textDecoration: 'none',
  color: theme.palette.text.secondary,
  '&:hover': {
    color: theme.palette.primary.main
  }
}))

export const AssignedPatientTable = () => {
  const dispatch = useDispatch()
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 20 })
  const { assignedPatients, isAssignedPatientsLoading } = useSelector(state => state.patient)
  const [isAssignPatientModal, setAssignPatientModal] = useState(false)
  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [statusFilter, setStatusFilter] = useState('all')

  const handleAssignClick = patient => {
    setSelectedPatient(patient)
    setAssignPatientModal(true)
  }

  const handleEditClick = patient => {
    setSelectedPatient(patient)
    setEditModalOpen(true)
  }

  const handleDeleteClick = patient => {
    setSelectedPatient(patient)
    setDeleteModalOpen(true)
  }

  const handleStatusFilterChange = (event, newStatus) => {
    setStatusFilter(newStatus)
  }

  const filteredPatients = assignedPatients.filter(patient => {
    if (statusFilter === 'all') return true
    return patient.status === statusFilter
  })

  const handleEditSave = () => {
    if (selectedPatient && selectedPatient._id) {
      dispatch(editPatient({ id: selectedPatient._id, data: selectedPatient })) // Dispatch edit action with the correct structure
      setEditModalOpen(false)
    } else {
      console.error('Selected patient is invalid or missing ID')
    }
  }

  const handleDeleteConfirm = () => {
    dispatch(deletePatient(selectedPatient._id)) // Dispatch delete action
    setDeleteModalOpen(false)
  }

  const handleAssignPatient = assigneeId => {
    dispatch(assignPatient({ id: selectedPatient._id, assigneeId }))
  }

  const usersColumns = [
    {
      flex: 0.1,
      minWidth: 10,
      field: '_id',
      headerName: 'INDEX'
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: 'name',
      headerName: 'User',
      renderCell: ({ row }) => {
        const { firstName, lastName, email } = row
        const fullName = `${firstName} ${lastName}`

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {renderClient(fullName)}
            <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
              <LinkStyled sx={{ textTransform: 'capitalize' }}>{fullName}</LinkStyled>
              <Typography noWrap variant='caption' sx={{ color: 'text.disabled' }}>
                {email}
              </Typography>
            </Box>
          </Box>
        )
      }
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: 'phoneNumber',
      headerName: 'Phone Number'
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: 'hasInsurance',
      headerName: 'Has Insurance'
    },
    {
      flex: 0.1,
      minWidth: 60,
      field: 'status',
      headerName: 'status'
    },
    {
      flex: 0.15,
      minWidth: 40,
      field: 'dob',
      headerName: 'Date of Birth'
    },
    {
      flex: 0.15,
      minWidth: 40,
      field: 'cinNumber',
      headerName: 'CIN Number'
    },
    {
      flex: 0.15,
      minWidth: 40,
      field: 'comment',
      headerName: 'Comment'
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.2,
      minWidth: 150,
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant='outlined'
            sx={{ p: '4px', width: 'fit-content', minWidth: 'unset' }}
            size='small'
            color='info'
            onClick={() => handleAssignClick(row)}
          >
            <Icon icon='ic:outline-assignment-ind' width='24' height='24' />
          </Button>
          <Button
            sx={{ p: '4px', width: 'fit-content', minWidth: 'unset' }}
            variant='outlined'
            size='small'
            color='primary'
            onClick={() => handleEditClick(row)}
          >
            <Icon icon='ic:baseline-mode-edit-outline' width='24' height='24' />
          </Button>
          <Button
            sx={{ p: '4px', width: 'fit-content', minWidth: 'unset' }}
            variant='contained'
            size='small'
            color='error'
            onClick={() => handleDeleteClick(row)}
          >
            <Icon icon='ic:baseline-delete' width='24' height='24' />
          </Button>
        </Box>
      )
    }
  ]

  return (
    <>
      <Box p={4}>
        <TextField
          label='Search by Name'
          variant='outlined'
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </Box>

      <Box mt={2} p={4}>
        <ToggleButtonGroup
          value={statusFilter}
          exclusive
          onChange={handleStatusFilterChange}
          aria-label='status filter'
        >
          <ToggleButton value='all' aria-label='all'>
            All
          </ToggleButton>
          <ToggleButton value='scheduled' aria-label='scheduled'>
            Scheduled
          </ToggleButton>
          <ToggleButton value='no-answer' aria-label='no-answer'>
            No Answer
          </ToggleButton>
          <ToggleButton value='wrong-number' aria-label='wrong-number'>
            Wrong Number
          </ToggleButton>
          <ToggleButton value='refused' aria-label='refused'>
            Refused
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box mt={2} p={4}>
        {isAssignedPatientsLoading ? (
          <Box display='flex' justifyContent='center' alignItems='center' height='300px'>
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            autoHeight
            getRowId={row => row?._id}
            rows={filteredPatients.filter(patient =>
              `${patient.firstName} ${patient.lastName}`.toLowerCase().includes(searchQuery.toLowerCase())
            )}
            columns={usersColumns}
            disableRowSelectionOnClick
            pageSizeOptions={[10, 25, 50]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            loading={isAssignedPatientsLoading} // Add this to show a loader in DataGrid
          />
        )}
      </Box>
      {/* Edit Patient Dialog */}
      <Dialog open={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>Edit Patient</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='First Name'
            type='text'
            fullWidth
            value={selectedPatient?.firstName || ''}
            onChange={e => setSelectedPatient({ ...selectedPatient, firstName: e.target.value })}
          />
          <TextField
            margin='dense'
            label='Last Name'
            type='text'
            fullWidth
            value={selectedPatient?.lastName || ''}
            onChange={e => setSelectedPatient({ ...selectedPatient, lastName: e.target.value })}
          />
          <TextField
            margin='dense'
            label='Email'
            type='email'
            fullWidth
            value={selectedPatient?.email || ''}
            onChange={e => setSelectedPatient({ ...selectedPatient, email: e.target.value })}
          />
          <Box>
            <Box mt={2}>
              <Typography variant='p'>Status</Typography>
            </Box>
            <Box mb={2}>
              <RadioGroup
                row
                value={selectedPatient?.status || ''}
                onChange={e => setSelectedPatient({ ...selectedPatient, status: e.target.value })}
              >
                <FormControlLabel value='scheduled' control={<Radio />} label='Scheduled' />
                <FormControlLabel value='no-answer' control={<Radio />} label='No Answer' />
                <FormControlLabel value='wrong-number' control={<Radio />} label='Wrong Number' />
                <FormControlLabel value='refused' control={<Radio />} label='Refused' />
              </RadioGroup>
            </Box>
          </Box>

          <TextField
            margin='dense'
            label='Comment'
            type='text'
            fullWidth
            value={selectedPatient?.comment || ''}
            onChange={e => setSelectedPatient({ ...selectedPatient, comment: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditModalOpen(false)} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleEditSave} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this patient?</DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Assign Patient Dialog */}
      <AssignPatientModal
        handleAssignPatient={handleAssignPatient}
        open={isAssignPatientModal}
        onClose={() => setAssignPatientModal(false)}
        defaultAssignee={selectedPatient?.assignee?.email}
      />
    </>
  )
}

const renderClient = row => {
  return (
    <CustomAvatar
      skin='light'
      color={row.avatarColor || 'primary'}
      sx={{ mr: 3, width: 32, height: 32, fontSize: '.875rem' }}
    >
      {getInitials(row ? row : 'John Doe')}
    </CustomAvatar>
  )
}
