"use client";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
  CircularProgress,
  useTheme
} from "@mui/material";

const API_URL = process.env.REACT_APP_HTTP_SERVER_HOST || "http://localhost:8000/api";

const UserList = ({ 
  userId, 
  token, 
  domainId, 
  loading, 
  setLoading, 
  setError, 
  selectedUser, 
  setSelectedUser,
  socketConnected 
}) => {
  const [users, setUsers] = useState([]);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // Define theme-aware colors
  const colors = {
    primary: theme.palette.primary.main,
    background: isDarkMode ? '#2b2c40' : theme.palette.background.paper,
    selectedItem: isDarkMode ? '#3b3b4f' : '#bbdefb',
    text: isDarkMode ? theme.palette.text.primary : theme.palette.text.primary,
    secondaryText: isDarkMode ? theme.palette.text.secondary : theme.palette.text.secondary,
  };

  // Fetch users after domain info is available
  useEffect(() => {
    if (userId && token && domainId) {
      fetchUsers();
    }
  }, [userId, token, domainId]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      console.log("Fetching users with:", {
        url: `${API_URL}/users`,
        subdomain: "health",
        domainId,
        tokenExists: !!token,
        tokenPrefix: token ? token.substring(0, 10) : "null",
      });
  
      if (!token) {
        throw new Error("No authentication token found");
      }
  
      // Ensure token starts with Bearer
      const authHeader = token.startsWith("Bearer ") ? token : `Bearer ${token}`;
  
      const response = await axios.get(`${API_URL}/users`, {
        headers: {
          "Content-Type": "application/json",
          "X-Subdomain": "health",
          "X-Domain-ID": domainId, // Add domain ID to headers
          "Authorization": authHeader,
        },
      });
  
      console.log("✅ Users fetched successfully:", response.data);
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error("❌ Error fetching users:", error);
  
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
        setError(`Unable to load users. Server responded with: ${error.response.status} - ${error.response.data.message || error.response.data.error || ''}`);
      } else if (error.request) {
        console.error("No response received:", error.request);
        setError("Unable to load users. No response from server.");
      } else {
        console.error("Error message:", error.message);
        setError(`Unable to load users: ${error.message}`);
      }
  
      setLoading(false);
    }
  };

  return (
    <Paper
  elevation={3}
  sx={{
    width: "25%",
    p: 2,
    overflowY: "auto",
    borderRadius: 0,
    backgroundColor: colors.background,
    color: colors.text,
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: colors.background,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#d3d3d3",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#b0b0b0",
    },
  }}
>

      <Typography variant="h6" gutterBottom>
Messages
      </Typography>
      <Button 
        variant="outlined" 
        size="small" 
        onClick={fetchUsers} 
        sx={{ mb: 2 }}
        disabled={loading || !domainId}
      >
        {loading && !selectedUser ? <CircularProgress size={20} /> : 'Refresh Users'}
      </Button>
      
      {loading && !selectedUser ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress size={24} />
        </Box>
      ) : users.length > 0 ? (
        <List>
          {users.map((userItem) => (
            <ListItem
              key={userItem._id}
              button
              selected={selectedUser?._id === userItem._id}
              onClick={() => setSelectedUser(userItem)}
              sx={{
                borderRadius: 1,
                mb: 0.5,
                '&.Mui-selected': {
                  backgroundColor: colors.selectedItem,
                  color: isDarkMode ? '#ffffff' : 'inherit',
                  '& .MuiListItemText-secondary': {
                    color: isDarkMode ? '#b0b0b0' : 'inherit',
                  }
                },
                '&:hover': {
                  backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
                }
              }}
            >
              <ListItemText 
                primary={userItem.username} 
                secondary={userItem.email}
                primaryTypographyProps={{
                  color: 'inherit'
                }}
                secondaryTypographyProps={{
                  color: 'textSecondary'
                }}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body2" color="textSecondary">
          No users found.
        </Typography>
      )}
    </Paper>
  );
};

export default UserList;