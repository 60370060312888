import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAssignees } from 'src/redux/patient/patient.action'

export const AssignPatientModal = ({ open, onClose, handleAssignPatient, defaultAssignee }) => {
  const dispatch = useDispatch()
  const { assignees } = useSelector(state => state.patient)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedAssignee, setSelectedAssignee] = useState(null)

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePatientSelect = assignee => {
    setSelectedAssignee(assignee)
    setAnchorEl(null)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  const handleModalClose = () => {
    onClose()
    setSelectedAssignee(null)
  }

  const handleConfirm = () => {
    handleAssignPatient(selectedAssignee._id)
    handleModalClose()
  }

  useEffect(() => {
    dispatch(getAssignees())
  }, [])

  return (
    <Dialog open={open} onClose={handleModalClose} sx={{ '& .MuiPaper-root': { width: '512px', mt: 4 } }}>
      <DialogTitle>Assign Patient</DialogTitle>
      <DialogContent>
        <Box>
          <Button onClick={handleDropdownOpen}>
            {defaultAssignee && defaultAssignee}
            {!defaultAssignee && selectedAssignee && selectedAssignee.email}
            {!defaultAssignee && !selectedAssignee && 'Select an assignee for this patient'}
          </Button>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleDropdownClose()}
          sx={{ '& .MuiMenu-paper': { width: 'fit-content', mt: 4 } }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {assignees?.map(assignee => (
            <MenuItem
              onClick={() => handlePatientSelect(assignee)}
              sx={{
                py: 2,
                px: 4,
                color: 'text.secondary',
                '& svg': { mr: 2, fontSize: '1.25rem', color: 'text.secondary' }
              }}
            >
              {assignee.email}
            </MenuItem>
          ))}
        </Menu>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalClose} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleConfirm} disabled={Boolean(!selectedAssignee)} color='secondary'>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
