import { createSlice } from '@reduxjs/toolkit'
import { getStats } from './stats.action'

const initialState = {
  isStatsLoading: false,
  isStatsLoaded: false,
  isStatsLoadingFailed: false,
  Stats: {}
}

const stats = createSlice({
  name: 'stats',
  initialState,

  extraReducers: builder => {
    builder
      /*******************************************************/
      .addCase(getStats.pending, state => {
        state.isStatsLoading = true
        state.isStatsLoaded = false
        state.isStatsLoadingFailed = false
      })
      .addCase(getStats.fulfilled, (state, action) => {
        state.isStatsLoading = false
        state.isStatsLoaded = true
        state.Stats = action.payload
      })
      .addCase(getStats.rejected, state => {
        state.isStatsLoading = false
        state.isStatsLoadingFailed = true
      })
  }
})

export default stats.reducer
