// ** Component Imports
import ThemeComponent from 'src/@core/theme/ThemeComponent'

// ** Third Party Import
import { Toaster } from 'react-hot-toast'
import { CacheProvider } from '@emotion/react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

// ** Contexts
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'
import { SocketProvider } from '../src/context/socketContext' // Import socket context

// ** Styled Components
import ReactHotToast from 'src/@core/styles/libs/react-hot-toast'

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** Routes
import { AppRouter } from './routes'

// ** Configs
import themeConfig from 'src/configs/themeConfig'

// ** Fix: Define clientSideEmotionCache before using it
const clientSideEmotionCache = createEmotionCache()

function Main(props) {
  const { emotionCache = clientSideEmotionCache } = props
  const Component = {}

  const setConfig = Component.setConfig ?? undefined

  return (
    <CacheProvider value={emotionCache}>
      <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
        <SettingsConsumer>
          {({ settings }) => (
            <HelmetProvider>
              <Helmet>
                <title>{`${themeConfig.templateName} - Health Heros`}</title>
                <meta name="description" content={`${themeConfig.templateName} HH`} />
                <meta name="keywords" content="HH" />
                <meta name="viewport" content="initial-scale=1, width=device-width" />
              </Helmet>
              <SocketProvider> {/* Wrap the app with SocketProvider */}
                <ThemeComponent settings={settings}>
                  <AppRouter>
                    <ReactHotToast>
                      <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                    </ReactHotToast>
                  </AppRouter>
                </ThemeComponent>
              </SocketProvider>
            </HelmetProvider>
          )}
        </SettingsConsumer>
      </SettingsProvider>
    </CacheProvider>
  )
}

export default Main
