import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-hot-toast'
import {
  getPatientsAPI,
  createPatientAPI,
  editPatientAPI,
  deletePatientAPI,
  getSinglePatient,
  getAssigneesAPI,
  assignPatientAPI,
  getAssignedPatientsAPI
} from './api'

// Fetch all patients
export const getPatients = createAsyncThunk('Patient/getPatients', async () => {
  const res = await getPatientsAPI()
  return res.data
})

//Fetch SIngle Patient
export const getPatient = createAsyncThunk('SinglePatient/getPatient', async payload => {
  const res = await getSinglePatient(payload)
  return res
})

// Create a new patient
export const createPatient = createAsyncThunk('Patient/createPatient', async payload => {
  const resultPromise = new Promise((resolve, reject) => {
    createPatientAPI(payload)
      .then(res => {
        resolve(res)
      })
      .catch(({ response }) => {
        reject(response?.data?.error || 'Something went wrong')
      })
  })

  toast.promise(resultPromise, {
    loading: 'Creating patient...',
    success: 'Patient created successfully',
    error: err => err
  })

  return await resultPromise
})

// Edit an existing patient

export const editPatient = createAsyncThunk('Patient/editPatient', async ({ id, data }, { rejectWithValue }) => {
  console.log('Payload being sent for editing:', { id, data }) // Log the payload

  try {
    const response = await editPatientAPI(id, data)
    toast.success('Patient updated successfully')
    return response.patient // Ensure the correct data is returned
  } catch (error) {
    const errorMessage = error.response?.data?.error || 'Something went wrong'
    toast.error(errorMessage)
    return rejectWithValue(errorMessage)
  }
})

// Delete a patient
export const deletePatient = createAsyncThunk('Patient/deletePatient', async (id, { dispatch }) => {
  const resultPromise = new Promise((resolve, reject) => {
    console.log('Attempting to delete patient with ID:', id)

    deletePatientAPI(id)
      .then(res => {
        resolve(res)
      })
      .catch(({ response }) => {
        if (response?.status === 404) {
          reject('Patient not found')
        } else {
          reject(response?.data?.error || 'Something went wrong')
        }
      })
  })

  toast.promise(resultPromise, {
    loading: 'Deleting patient...',
    success: 'Patient deleted successfully',
    error: err => err
  })

  await resultPromise

  // Dispatch getPatients to refresh the list of patients after deletion
  dispatch(getPatients())

  return id // Optionally return the deleted patient ID
})

// Get Assignees
export const getAssignees = createAsyncThunk('Assignee/getAssignee', async () => {
  const res = await getAssigneesAPI()
  return res.users
})

export const assignPatient = createAsyncThunk('Patient/assignPatient', async ({ id, assigneeId }, { dispatch }) => {
  const resultPromise = new Promise((resolve, reject) => {
    assignPatientAPI(id, assigneeId)
      .then(res => {
        resolve(res)
      })
      .catch(({ response }) => {
        if (response?.status === 404) {
          reject('Patient not found')
        } else {
          reject(response?.data?.error || 'Something went wrong')
        }
      })
  })

  toast.promise(resultPromise, {
    loading: 'Assigning patient...',
    success: 'Patient assigned successfully',
    error: err => err
  })

  await resultPromise

  dispatch(getPatients())

  return id
})

// Fetch all patients
export const getAssignedPatients = createAsyncThunk('Patient/getAssignedPatients', async () => {
  const res = await getAssignedPatientsAPI()
  return res.data.patients
})
