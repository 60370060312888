'use client'
import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import io from 'socket.io-client'
import { Box, Typography, Paper, Button, Alert } from '@mui/material'
import { AuthContext } from '../../context/AuthContext'
import { getSubdomain } from '../../utils/getSubdomain'
import UserList from './userList'
import ChatWindow from './chatWindow'

const API_URL = process.env.REACT_APP_HTTP_SERVER_HOST
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'http://localhost:3000'

// Create socket connection outside component to avoid multiple connections
let socket

const MessageView = () => {
  const { user } = useContext(AuthContext)
  const userId = user?._id

  // Get subdomain and token using the proper key
  const subdomain = getSubdomain()
  const subdomainToProjectMap = {
    health: 'health'
  }
  const projectName = subdomainToProjectMap[subdomain] || 'Default'
  const tokenKey = `${projectName}-admin`

  // Get token from localStorage using the correct key for the current subdomain
  const token = localStorage.getItem(tokenKey)

  const [selectedUser, setSelectedUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [socketConnected, setSocketConnected] = useState(false)
  const [domainId, setDomainId] = useState(null)

  // Debug auth state
  useEffect(() => {
    console.log(' Auth Debug:', {
      subdomain,
      projectName,
      tokenKey,
      tokenExists: !!token,
      userExists: !!user,
      userId,
      tokenValue: token ? `${token.substring(0, 10)}...` : 'null'
    })
  }, [subdomain, projectName, tokenKey, token, user, userId])

  // Initialize socket connection when user is authenticated
  useEffect(() => {
    if (userId && token && !socket) {
      try {
        console.log('Attempting to connect socket with userId:', userId)
        socket = io(SOCKET_URL, {
          withCredentials: true,
          transports: ['websocket'],
          auth: { token },
          reconnection: true,
          reconnectionAttempts: 5,
          reconnectionDelay: 1000
        })

        socket.on('connect', () => {
          console.log('Socket connected with ID:', socket.id)
          setSocketConnected(true)
          socket.emit('joinRoom', userId)
        })

        socket.on('connect_error', err => {
          console.error(' Socket connection error:', err)
          setError('Failed to connect to chat server. Please refresh the page.')
        })

        socket.on('disconnect', reason => {
          console.log(' Socket disconnected:', reason)
          setSocketConnected(false)
        })

        return () => {
          if (socket) {
            console.log(' Cleaning up socket connection')
            socket.disconnect()
            socket = null
            setSocketConnected(false)
          }
        }
      } catch (error) {
        console.error(' Error initializing socket:', error)
        setError('Unable to initialize chat connection. Please refresh the page.')
      }
    }
  }, [userId, token])

  // Set domain ID
  useEffect(() => {
    if (userId && token) {
      let detectedSubdomain = getSubdomain()

      if (!detectedSubdomain) {
        detectedSubdomain = '66e058d8c0b32ff57db2a275' // Fallback to _id
      }

      setDomainId(detectedSubdomain)
      setLoading(false)
      console.log(' Using domain ID:', detectedSubdomain)
    }
  }, [userId, token])

  if (!userId || !token) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
        <Paper elevation={3} sx={{ p: 4, maxWidth: '500px' }}>
          <Typography variant='h6' gutterBottom>
            Authentication Required
          </Typography>
          <Alert severity='warning'>Please log in to access the messaging feature.</Alert>
          <Button variant='contained' color='primary' sx={{ mt: 2 }} onClick={() => (window.location.href = '/login')}>
            Go to Login
          </Button>
        </Paper>
      </Box>
    )
  }

  const renderReconnectButton = () => {
    if (!socketConnected && userId && token) {
      return (
        <Button
          variant='contained'
          color='primary'
          sx={{ mt: 2 }}
          onClick={() => {
            if (socket) {
              socket.disconnect()
              socket = null
            }
            setSocketConnected(false)
            window.location.reload()
          }}
        >
          Reconnect
        </Button>
      )
    }
    return null
  }

  return (
    <Box display='flex' height='80vh' width='100%' sx={{ overflow: 'hidden' }}>
      {process.env.NODE_ENV === 'development' && (
        <Box
          sx={{
            position: 'absolute',
            top: error ? 50 : 10,
            left: 10,
            zIndex: 9998,
            bgcolor: 'rgba(0,0,0,0.7)',
            color: 'white',
            p: 1,
            borderRadius: 1,
            fontSize: '0.7rem'
          }}
        >
          Domain ID: {domainId || 'Loading...'}
        </Box>
      )}

      {error && (
        <Alert
          severity='error'
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 9999
          }}
          onClose={() => setError(null)}
          action={renderReconnectButton()}
        >
          {error}
        </Alert>
      )}

      <Box
        sx={{
          position: 'absolute',
          // bottom: 100,
          top: 128,
          right: 125,
          // left: 0,
          // zIndex: 9999,
          display: 'flex',
          alignItems: 'center',
          backgroundColor: socketConnected ? 'green' : 'red',
          color: 'white',
          p: 1,
          borderRadius: 1,
          fontSize: '0.8rem'
        }}
      >
        {socketConnected ? 'Connected' : 'Disconnected'}
      </Box>

      <UserList
        userId={userId}
        token={token}
        domainId={domainId}
        loading={loading}
        setLoading={setLoading}
        setError={setError}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        socketConnected={socketConnected}
      />

      <ChatWindow
        userId={userId}
        token={token}
        domainId={domainId}
        loading={loading}
        setLoading={setLoading}
        setError={setError}
        selectedUser={selectedUser}
        socket={socket}
        socketConnected={socketConnected}
      />
    </Box>
  )
}

export default MessageView
