// pages/message/index.js
import { Container } from '@mui/material';
import MessageView from '../../views/messages/messageView';
import { GuardWrapper } from 'src/layouts/GuardWrapper';

const MessagesPage = () => {
  return (
    <GuardWrapper>
<Container
  maxWidth="xl"
  sx={{
    mt: 4,
    borderRadius: 2,
    border: '1px solid #ffffff',
    overflow: 'hidden',
    bgcolor: (theme) =>
      theme.palette.mode === 'light' ? '#ffffff' : '#2b2c40', 
    color: (theme) =>
      theme.palette.mode === 'light' ? '' : '', 
  }}
>
      <MessageView />
  
    </Container>
    </GuardWrapper>
  );
};

export default MessagesPage;


