import { createContext, useContext, useEffect, useRef } from 'react'
import io from 'socket.io-client'

const SocketContext = createContext()

export const SocketProvider = ({ children }) => {
  const socket = useRef(null)

  useEffect(() => {
    socket.current = io('http://localhost:8000')
    return () => {
      socket.current.disconnect()
    }
  }, [])

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
}

export const useSocket = () => {
  return useContext(SocketContext)
}
