import { combineReducers } from '@reduxjs/toolkit'

import patientSlicer from 'src/redux/patient/slicer'
import statsSlicer from 'src/redux/stats/slicer'

const rootReducer = combineReducers({
  patient: patientSlicer,
  stats: statsSlicer
})

export default rootReducer
