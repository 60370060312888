"use client";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Badge,
  IconButton,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SendIcon from "@mui/icons-material/Send";

const API_URL = process.env.REACT_APP_HTTP_SERVER_HOST

const ChatWindow = ({
  userId,
  token,
  domainId,
  loading,
  setLoading,
  setError,
  selectedUser,
  socket,
  socketConnected,
  onBack,
}) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [pendingMessages, setPendingMessages] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notification, setNotification] = useState(null);
  const [openNotification, setOpenNotification] = useState(false);
  const messagesEndRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDarkMode = theme.palette.mode === "dark";

  // Request notification permission on component mount
  useEffect(() => {
    if (Notification.permission !== "granted" && Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        }
      });
    }
  }, []);

  // Scroll to bottom when messages change
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Fetch messages when selected user changes
  useEffect(() => {
    if (selectedUser && userId && token && domainId) {
      fetchMessages(selectedUser._id);
      getUnreadCount();
    }
  }, [selectedUser, userId, token, domainId]);

  // Socket event listeners
  useEffect(() => {
    if (!socket) return;

    const handleNewMessage = (newMessage) => {
      console.log("✅ New message received:", newMessage);

      // Update messages state
      setMessages((prev) => {
        const messageExists = prev.some(
          (msg) =>
            msg._id === newMessage._id ||
            (msg.tempId && msg.content === newMessage.content && msg.sender === newMessage.sender)
        );

        if (messageExists) {
          return prev.map((msg) =>
            msg.tempId && msg.content === newMessage.content && msg.sender === newMessage.sender
              ? { ...newMessage, confirmed: true }
              : msg
          );
        } else {
          return [...prev, { ...newMessage, confirmed: true }];
        }
      });

      // Show browser notification if the app is not focused
      if (document.visibilityState !== "visible" && Notification.permission === "granted") {
        new Notification(`New message from ${newMessage.senderUsername}`, {
          body: newMessage.content,
          icon: "", 
        });
      }

      // Show in-app notification
      setNotification(`New message from ${newMessage.senderUsername}: ${newMessage.content}`);
      setOpenNotification(true);
    };

    socket.on("receiveMessage", handleNewMessage);

    return () => {
      socket.off("receiveMessage", handleNewMessage);
    };
  }, [socket, userId, selectedUser]);

  // Fetch messages from server
  const fetchMessages = async (receiverId) => {
    try {
      setLoading(true);
      const authHeader = token.startsWith("Bearer ") ? token : `Bearer ${token}`;

      const res = await axios.get(`${API_URL}/messages/user/${receiverId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
          "X-Subdomain": domainId,
        },
      });

      const fetchedMessages = res.data.map((msg) => ({
        _id: msg._id,
        sender: typeof msg.sender === "object" ? msg.sender._id : msg.sender,
        receiver: typeof msg.receiver === "object" ? msg.receiver._id : msg.receiver,
        content: msg.content,
        timestamp: msg.timestamp || msg.createdAt || new Date().toISOString(),
        senderUsername: typeof msg.sender === "object" ? msg.sender.username : "Unknown",
        receiverUsername: typeof msg.receiver === "object" ? msg.receiver.username : selectedUser.username,
        read: msg.read || false,
        confirmed: true,
        domainId: msg.domain || domainId,
      }));

      setMessages(fetchedMessages);
    } catch (error) {
      console.error("❌ Error fetching messages:", error);
      setError("Failed to load messages.");
    } finally {
      setLoading(false);
    }
  };

  // Send a new message
  const sendMessage = () => {
    const trimmedMessage = String(message).trim();

    if (trimmedMessage && userId && selectedUser && domainId) {
      const tempId = `temp-${Date.now()}`;
      const newMessage = {
        tempId: tempId,
        sender: userId,
        receiver: selectedUser._id,
        content: trimmedMessage,
        timestamp: new Date().toISOString(),
        senderUsername: "You",
        receiverUsername: selectedUser.username,
        read: false,
        confirmed: false,
        domainId: domainId,
      };

      setMessages((prev) => [...prev, newMessage]);
      setPendingMessages((prev) => [...prev, newMessage]);
      setMessage("");

      if (socket && socketConnected) {
        socket.emit("sendMessage", newMessage);
      } else {
        sendMessageViaAPI(newMessage);
      }

      setTimeout(scrollToBottom, 100);
    }
  };

  // Send message via API as fallback
  const sendMessageViaAPI = async (messageData) => {
    try {
      const authHeader = token.startsWith("Bearer ") ? token : `Bearer ${token}`;

      const res = await axios.post(
        `${API_URL}/messages`,
        {
          receiverId: messageData.receiver,
          content: messageData.content,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader,
            "X-Subdomain": domainId,
          },
        }
      );

      const confirmedMessage = {
        ...res.data,
        confirmed: true,
      };

      setMessages((prev) => prev.map((msg) => (msg.tempId === messageData.tempId ? confirmedMessage : msg)));
      setPendingMessages((prev) => prev.filter((msg) => msg.tempId !== messageData.tempId));
    } catch (error) {
      console.error("❌ Error sending message via API:", error);
      setError("Failed to send message.");
    }
  };

  // Mark messages as read
  const markMessagesAsRead = async (senderId) => {
    try {
      const authHeader = token.startsWith("Bearer ") ? token : `Bearer ${token}`;

      await axios.put(
        `${API_URL}/messages/read/${senderId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authHeader,
            "X-Subdomain": domainId,
          },
        }
      );

      setMessages((prev) =>
        prev.map((msg) => (msg.sender === senderId && !msg.read ? { ...msg, read: true } : msg))
      );

      getUnreadCount();
    } catch (error) {
      console.error("❌ Error marking messages as read:", error);
    }
  };

  // Get unread message count
  const getUnreadCount = async () => {
    try {
      const authHeader = token.startsWith("Bearer ") ? token : `Bearer ${token}`;

      const res = await axios.get(`${API_URL}/messages/unread`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authHeader,
          "X-Subdomain": domainId,
        },
      });

      setUnreadCount(res.data.unreadCount);
    } catch (error) {
      console.error("❌ Error getting unread count:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        width: isMobile ? "100%" : "75%",
        display: "flex",
        flexDirection: "column",
        borderRadius: isMobile ? 0 : "0 4px 4px 0",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {selectedUser ? (
        <>
          <Box
            sx={{
              p: isMobile ? 1.5 : 2,
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: isDarkMode ? theme.palette.background.paper : "#ffffff",
            }}
          >
            <Typography variant={isMobile ? "subtitle1" : "h6"}>
              {selectedUser.username}
              {unreadCount > 0 && <Badge badgeContent={unreadCount} color="primary" sx={{ ml: 5 }} />}
            </Typography>
            <Box>
              <IconButton
                onClick={() => markMessagesAsRead(selectedUser._id)}
                disabled={loading || !domainId}
                size="small"
                sx={{ mr: 1 }}
                title="Mark all as read"
              >
                <DoneAllIcon />
              </IconButton>
              <IconButton
                onClick={() => fetchMessages(selectedUser._id)}
                disabled={loading || !domainId}
                size="small"
                color="primary"
                title="Refresh"
              >
                <RefreshIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            flex={1}
            sx={{
              bgcolor: isDarkMode ? "#2b2c40" : "#f5f5f5",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
              </Box>
            ) : (
              messages.map((msg, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: msg.sender === userId ? "flex-end" : "flex-start",
                    width: "100%",
                    p: 1,
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "60%",
                      p: 1.5,
                      borderRadius: 2,
                      bgcolor: msg.sender === userId ? theme.palette.primary.main : isDarkMode ? "#232336" : "#e0e0e0",
                      color: msg.sender === userId ? "#ffffff" : isDarkMode ? "#ffffff" : "#000000",
                    }}
                  >
                    <Typography variant="body1">{msg.content}</Typography>
                    <Typography variant="caption" sx={{ opacity: 0.75, display: "block", mt: 0.5 }}>
                      {new Date(msg.timestamp).toLocaleTimeString()}
                    </Typography>
                  </Box>
                </Box>
              ))
            )}
            <div ref={messagesEndRef} />
          </Box>
          <Box
            sx={{
              display: "flex",
              p: isMobile ? 1 : 2,
              borderTop: 1,
              borderColor: "divider",
              backgroundColor: isDarkMode ? theme.palette.background.paper : "#ffffff",
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type a message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)} 
              onKeyPress={handleKeyPress}
              sx={{
                mr: 1,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "24px",
                },
              }}
              size={isMobile ? "small" : "medium"}
              disabled={!domainId}
              multiline
              maxRows={3}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={sendMessage}
              disabled={!message.trim() || !domainId}
              sx={{
                borderRadius: "50%",
                minWidth: isMobile ? "40px" : "48px",
                width: isMobile ? "40px" : "48px",
                height: isMobile ? "40px" : "48px",
                p: 0,
              }}
            >
              <SendIcon />
            </Button>
          </Box>
        </>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <Typography variant="body1" color="textSecondary">
            Select a user to start chatting
          </Typography>
        </Box>
      )}

      {/* In-App Notification Snackbar */}
      <Snackbar
        open={openNotification}
        autoHideDuration={6000}
        onClose={() => setOpenNotification(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={() => setOpenNotification(false)} severity="info" sx={{ width: "100%" }}>
          {notification}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ChatWindow;