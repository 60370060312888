import { axiosClient } from 'src/configs/axios'

// Get Single Stats
export const getSingleStats = async () => {
  try {
    const response = await axiosClient.get(`/stats`)
    return response
  } catch (error) {
    console.error(`Error getting stats data`, error)
    throw error
  }
}
